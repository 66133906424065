import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'assets/lang/en.json';
import da from 'assets/lang/da.json';

export const fallback = 'da';
export const defaultNamespace = 'common';
export const namespaces = ['common'];

export const resources = {
  en: {
    name: 'English',
    ...en,
  },
  da: {
    name: 'Dansk',
    ...da,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'da',

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
