import React, {
  ReactElement,
  useState,
  useEffect,
  ChangeEvent,
  SyntheticEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/atoms/TextInput';
import Button from 'components/atoms/Button';
import Alert from 'components/atoms/Alert';
import { Api, URL_CONSTANTS } from 'services/api';
import useURLParam from 'hooks/useURLParam';

type Props = {
  user: {
    name: string;
  } | null;
  onSuccessHandler: (state: boolean) => void;
};

const ResetForm = ({ user, onSuccessHandler }: Props): ReactElement => {
  const { t } = useTranslation();
  const [finish, setFinish] = useState<boolean>(false);
  const [password, setPassword] = useState<string | null>(null);
  const [repeatPassword, setRepeatPassword] = useState<string | null>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [value] = useURLParam('token', '');

  /**
   * Submit the form by setting the finished flag in state
   * @param {SyntheticEvent} e
   */
  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    return setFinish(true);
  };

  useEffect(() => {
    // if the passwords are valid, and the submit btn is clicked
    // post the new password, if it works redirect to success screen
    if (valid && !error && finish) {
      Api.post(URL_CONSTANTS.RESET, { password, token: value })
        .then(() => {
          return onSuccessHandler(true);
        })
        .catch((err) => setError(err));
    }
  }, [finish, valid, error, password, onSuccessHandler, value]);

  useEffect(() => {
    // Check if passwords are the same
    if (repeatPassword === password) {
      setError(null);
      setValid(true);
    } else {
      setValid(false);
      setError(t('common:passwordsMustMatch'));
    }
  }, [repeatPassword, password, valid, setError, setValid, t]);

  return (
    <main className="container flex flex-col items-center content-center justify-center h-screen p-4 mx-auto">
      <div className="lg:w-3/6 w-1/1">
        <Alert text={error ?? ''} />
        <div className="p-2 my-2 bg-white border-l-8 rounded-lg shadow-md border-primary">
          <div className="mt-2">
            <div className="m-2 mb-4">
              <h2 className="text-2xl">
                {t('info:forgotPasswordHeader', { user: user?.name })}
              </h2>
              <p className="text-base text-gray-300">
                {t('info:forgotPasswordBody')}
              </p>
            </div>
            <div className="m-2">
              <form onSubmit={onSubmit}>
                <div className="my-2">
                  <TextInput
                    placeholder={t('common:newPassword')}
                    type="password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    autoComplete="false"
                  />
                </div>
                <div className="my-2">
                  <TextInput
                    placeholder={t('common:repeatNewPassword')}
                    type="password"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setRepeatPassword(e.target.value)
                    }
                    autoComplete="false"
                  />
                </div>
                <div className="flex justify-end my-2">
                  <Button
                    text={t('common:confirmNewPassword')}
                    type="submit"
                    disabled={!valid}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetForm;
