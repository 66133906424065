import axios from 'axios';
import URL_CONSTANTS from './url-constants';

export const AUTH_HEADER_KEY = 'Authorization';

const axiosInstance = axios.create({
  baseURL: URL_CONSTANTS.BASE,
  timeout: 10000,
  headers: {
    common: {
      [AUTH_HEADER_KEY]: null,
      'Content-Type': 'application/json',
      'Key-Inflection': 'camel',
    },
  },
});

export const setHeader = (key: string, value: string | number): void => {
  axiosInstance.defaults.headers.common[key] = value;
};

export default axiosInstance;
