const API_BASE = process.env.REACT_APP_API_BASE_URL;
const PREFIX = '/auth';
export default {
  BASE: API_BASE,
  SESSION: `${PREFIX}/session`,
  EMPLOYEES: `${PREFIX}/employees`,
  QUESTION: `${PREFIX}/questions`,
  EMPLOYEE_AUTHENTICATE: `${PREFIX}/employees/authenticate`,
  ANSWERS: `${PREFIX}/answer`,
  VALIDATE: `${PREFIX}/password/validate`,
  RESET: `${PREFIX}/password/reset`,
};
