import React from 'react';

export type AlertProps = {
  text: string;
  type?: 'warning' | 'error' | 'success' | 'default';
  className?: string;
};

const AlertColors = {
  default: 'bg-primary-light bg-opacity-50',
  success: 'bg-green-500 text-white',
  warning: 'bg-yellow-500 text-white',
  error: 'bg-red-500 text-white',
};

const Alert: React.FC<AlertProps> = ({
  text,
  type = 'default',
  className = '',
}) => {
  if (text !== '') {
    return (
      <div
        className={`w-full rounded-lg shadow-lg px-4 py-2 ${AlertColors[type]} ${className}`}
      >
        {text}
      </div>
    );
  }
  return null;
};

export default Alert;
