import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { Api, URL_CONSTANTS } from 'services/api';
import useURLParam from 'hooks/useURLParam';
import ResetForm from 'components/views/ResetForm';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/atoms/LoadingSpinner';
import ResetSuccess from 'components/views/ResetSuccess';
import Base from 'components/templates/Base';

const Router = (): ReactElement => {
  const { t } = useTranslation();
  const firstRender = useRef(true);
  const [passwordSaved, setPasswordSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [valueValid, setValueValid] = useState<boolean>(false);
  const [user, setUser] = useState<{ name: string } | null>(null);

  const [value] = useURLParam('token', '');

  useEffect(() => {
    // Validate the token, set the user if valid
    // Redirect to wrong token page if not valid
    if (value && firstRender.current) {
      Api.post(URL_CONSTANTS.VALIDATE, { token: value })
        .then(({ status, data }) => {
          setValueValid(true);

          if (status === 204) {
            setPasswordSaved(true);
          }
          if (status === 200) {
            setUser(data.employee);
          }

          return setLoading(false);
        })
        .catch((err) => {
          setValueValid(false);
          return setLoading(false);
        });

      firstRender.current = false;
    } else {
      setLoading(false);
    }
  }, [value, setUser]);

  if (loading) {
    return (
      <Base>
        <LoadingSpinner />
      </Base>
    );
  }
  if (passwordSaved) {
    return <ResetSuccess />;
  }
  if (value && valueValid) {
    return <ResetForm user={user} onSuccessHandler={setPasswordSaved} />;
  }

  return (
    <Base>
      <h1 className="text-2xl text-primary">{t('info:invalidTokenHeader')}</h1>
      <h3 className="text-2xl text-gray-500">{t('info:invalidTokenBody')}</h3>
    </Base>
  );
};

export default Router;
