import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Router from 'components/templates/Router';
import './assets/stylesheets/index.css';
import 'services/i18n';
import reportWebVitals from './reportWebVitals';

const BaseApp = (): ReactElement => {
  const [t] = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {t('app:name')} | {t('app:webApp')}
        </title>
      </Helmet>
      <Router />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BaseApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
