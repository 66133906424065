import React, { ReactElement } from 'react';
import Base from 'components/templates/Base';
import { useTranslation } from 'react-i18next';

const ResetSuccess = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Base>
      <h1 className="text-2xl text-primary">
        {t('info:passwordResetSuccessHeader')}
      </h1>
      <h3 className="text-xl text-gray-500">
        {t('info:passwordResetSuccessBody')}
      </h3>
    </Base>
  );
};

export default ResetSuccess;
