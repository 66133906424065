import React, { ButtonHTMLAttributes, ReactElement } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  buttonSize?: 'sm' | 'lg';
}

const Button = ({
  text,
  buttonSize = 'sm',
  className,
  ...rest
}: ButtonProps): ReactElement => {
  const sizeClasses =
    buttonSize === 'sm' ? 'px-12 py-3 text-base' : 'px-6 py-2 text-xl';

  return (
    <button
      type="button"
      value={text}
      className={`${sizeClasses} bg-primary hover:bg-primary-dark text-white disabled:opacity-50 rounded-lg uppercase ${className}`}
      {...rest}
    >
      {text}
    </button>
  );
};

export default Button;
