import React, { ReactElement, ReactNode } from 'react';

type Props = { children: ReactNode };

const Base = ({ children }: Props): ReactElement => {
  return (
    <main className="container flex flex-col items-center content-center justify-center h-screen p-4 mx-auto">
      <div className="lg:w-3/6 w-1/1">{children}</div>
    </main>
  );
};

export default Base;
